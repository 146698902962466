export const getUniqueSortedTags = posts => {
  const tags = getTags(posts);
  if (tags.length === 0) return [];

  const uniqueTags = removeDuplicatesAndFlatten(tags);
  return uniqueTags.sort(Intl.Collator().compare);
};

const getTags = posts => {
  const allTags = [];

  posts.forEach(post => {
    const { tags } = post.frontmatter;
    allTags.push(tags);
  });

  return allTags;
};

const removeDuplicatesAndFlatten = arr => {
  const flatArray = [].concat.apply([], arr);
  return flatArray.filter((a, b) => flatArray.indexOf(a) === b);
};
