import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";

import Layout from "../components/Layout";
import SEO from "react-seo-component";
import { useSiteMetadata } from "../hooks/useSiteMetadata";
import { getUniqueSortedTags } from "../shared/utils/tagHelpers";
import TagList from "../shared/components/TagList";
import StyledTitle from "../shared/components/Title";

export default ({ data }) => {
  const {
    description,
    title,
    siteUrl,
    siteLanguage,
    siteLocale,
    twitterUsername,
    authorName,
    image
  } = useSiteMetadata();
  const posts = data.allMdx.nodes;
  const tags = getUniqueSortedTags(posts);

  return (
    <React.Fragment>
      <Layout>
        <SEO
          title={title}
          description={description}
          pathname={siteUrl}
          siteLanguage={siteLanguage}
          siteLocale={siteLocale}
          twitterUsername={twitterUsername}
          authorName={authorName}
          author={authorName}
          defaultImage={image}
        />
        <StyledDiv>
          <StyledTitle label={"Tags"} />
          <StyledTagList tags={tags} showIcon={false} />
        </StyledDiv>
      </Layout>
    </React.Fragment>
  );
};

const StyledDiv = styled.div`
  width: 50%;
  margin: auto;
  text-align: center;

  @media (max-width: 768px) {
    width: 80%;
  }

  @media (max-width: 1024px) {
    width: 65%;
  }
`;

const StyledTagList = styled(TagList)`
  font-size: 1.5em;
`;

export const query = graphql`
  query SITE_TAGS_QUERY {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { published: { eq: true } } }
    ) {
      nodes {
        id
        frontmatter {
          tags
        }
      }
    }
  }
`;
